import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SanityTexte from "./SanityTexte"
import Mailchimp from "./ui/Mailchimp"
import { _localizeText } from "../core/utils"

const query = graphql`
  query {
    sanityFooter {
      contact: _rawContact(resolveReferences: { maxDepth: 10 })
      partners: _rawPartners(resolveReferences: { maxDepth: 10 })
      credits: _rawCredits(resolveReferences: { maxDepth: 10 })
    }
  }
`

const Footer = () => {
  const { sanityFooter } = useStaticQuery(query)
  return (
    <footer className="p-sm pb-lg- bg-gray-100 text-primary -">
      <div className="row">
        <div className="col-md-11 col-xs-20">
          <div className="mb-md md:max-w-[500px] text-lg md:text-xl lh-1 overflow-hidden">
            <Mailchimp
              action={
                "https://victoire-cyclessss.us14.list-manage.com/subscribe/post?u=634063fedd1031149ebc97975&amp;id=9e99003d16"
              }
              fields={[
                {
                  name: "EMAIL",
                  placeholder: _localizeText("emailPlaceHolder"),
                  type: "email",
                  required: true,
                },
              ]}
              messages={{
                sending: _localizeText("sending"),
                success: _localizeText("success"),
                error: _localizeText("error"),
                empty: _localizeText("empty"),
                duplicate: _localizeText("duplicate"),
                button: _localizeText("send"),
              }}
            />
          </div>
        </div>
        <div className="col-md-9 col-xs-20">
          <div className="flex flex-wrap md:flew-nowrap justify-between">
            {sanityFooter.contact && (
              <div className="item mb-1e md:mb-0 w-1/2 md:w-auto">
                <h3 className="text-md-alt mb-05e text-gray-50">Contact</h3>
                <SanityTexte input={sanityFooter.contact} />
              </div>
            )}
            {sanityFooter.partners && (
              <div className="item mb-1e md:mb-0 w-1/2 md:w-auto">
                <h3 className="text-md-alt mb-05e text-gray-50">Partners</h3>
                <SanityTexte input={sanityFooter.partners} />
              </div>
            )}
            {sanityFooter.credits && (
              <div className="item mb-1e md:mb-0">
                <h3 className="text-md-alt mb-05e text-gray-50">Crédits</h3>
                <SanityTexte input={sanityFooter.credits} />
              </div>
            )}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
