import React from "react"
import PortableText from "@sanity/block-content-to-react"
import { Link } from "gatsby"
import { _linkResolver } from "../core/utils"

const SanityTexte = ({ input }) => {
  // console.log(input)
  const serializers = {
    types: {
      block(props) {
        // console.log(props.node.style)
        switch (props.node.style) {
          case "h2":
            return <h2>{props.children}</h2>
          case "text-lg":
            return <p className="text-lg">{props.children}</p>
          default:
            return <p>{props.children}</p>
        }
      },
    },

    marks: {
      sup: ({ children }) => <sup>{children}</sup>,
      internalLink: ({ mark, children }) => {
        return <Link to={_linkResolver(mark.reference)}>{children}</Link>
      },
      link: ({ mark, children }) => {
        const { href } = mark
        return (
          <a href={href} target="_blank" rel="noopener, noreferrer">
            {children}
          </a>
        )
      },
      "text-center": ({ mark, children }) => (
        <span className="text-center block">{children}</span>
      ),
      "text-right": ({ mark, children }) => (
        <span className="text-right block">{children}</span>
      ),
    },
  }

  return (
    <div className="texte">
      <PortableText blocks={input} serializers={serializers} />
    </div>
  )
}

export default SanityTexte
