import React from "react"
import { publish } from "pubsub-js"
import ReactPlayer from "react-player"

const AudioPlayerAlt = ({ src, playing }) => {
  // const playerAudioRef = useRef()
  // const player = useRef()
  // useEffect(() => {
  //   const player = playerAudioRef.current
  //   player.addEventListener("ended", _onEnded)
  //   player.addEventListener("timeupdate", _onTimeUpdate)

  //   return () => {
  //     player.removeEventListener("ended", _onEnded)
  //     player.removeEventListener("timeupdate", _onTimeUpdate)
  //   }
  // }, [src])

  // const _onTimeUpdate = () => {
  //   const { currentTime, duration } = playerAudioRef.current
  //   console.log(currentTime, duration)
  // }

  // useEffect(() => {
  //   // console.log(refMarquee.current)
  //   if (playing) {
  //     playerAudioRef.current.play()
  //   } else {
  //     playerAudioRef.current.pause()
  //   }
  // }, [playing])

  const _onReady = () => {}
  const _onEnded = () => {
    publish("AUDIO_END")
  }

  const playerConfig = {}
  return (
    <div className="player-audio fixed hidden">
      <ReactPlayer
        className={`player w-full `}
        url={src}
        // url="https://soundcloud.com/popotes-podcast/8-je-mange-donc-je-snobe-avec-adele-van-reeth?in=popotes-podcast/sets/popotes-podcast-saison-1"
        // url={"https://soundcloud.com/oizo-mr-3000/solid-feat-marilyn-manson"}
        playing={playing}
        // loop={true}
        muted={false}
        playsinline
        config={playerConfig}
        width="100%"
        height="auto"
        onReady={_onReady}
        onEnded={_onEnded}
      />
    </div>
  )
}

export default AudioPlayerAlt
