import clsx from "clsx"
import React from "react"
import { useState } from "react"
import styled from "styled-components"
import Mailchimp from "./Mailchimp"
import { _localizeText } from "../../core/utils"
import { useScroll } from "../../hooks/useScroll"
import { useEffect } from "react"
import { subscribe, unsubscribe } from "pubsub-js"

const Container = styled.div`
  background-color: #35a900;
  button {
    line-height: 1;
  }
`
const NewsletterWrapper = () => {
  const [active, setActive] = useState()
  const [remove, setRemove] = useState()
  const { scrollY, scrollDirection, isBottom } = useScroll()

  useEffect(() => {
    const token = subscribe("MAILCHIMP_SUCCESS", () => {
      setRemove(true)
    })

    return () => unsubscribe(token)
  }, [])

  useEffect(() => {
    if (remove || scrollDirection === "down" || isBottom) {
      setActive(false)
      return
    }
    if (scrollDirection === "up" && scrollY > 1000) {
      setActive(true)
    }
  }, [remove, scrollY, scrollDirection, isBottom])

  return (
    <Container
      className={clsx(
        "newsletter-wrapper fixed w-full md:w-1/2 bottom-0 right-0 transition-transform p-sm text-primary z-50",
        active ? "translate-y-0" : "translate-y-full"
      )}
    >
      <div className="inner">
        <div className="header text-md md:text-lg absolute right-0 z-10">
          <button className="close" onClick={() => setRemove(true)}>
            
          </button>
        </div>
        <div className="text-lg md:text-xl">
          <Mailchimp
            action={
              "https://victoire-cyclessss.us14.list-manage.com/subscribe/post?u=634063fedd1031149ebc97975&amp;id=9e99003d16"
            }
            fields={[
              {
                name: "EMAIL",
                placeholder: _localizeText("emailPlaceHolder"),
                type: "email",
                required: true,
              },
            ]}
            messages={{
              sending: _localizeText("sending"),
              success: _localizeText("success"),
              error: _localizeText("error"),
              empty: _localizeText("empty"),
              duplicate: _localizeText("duplicate"),
              button: _localizeText("send"),
            }}
          />
        </div>
      </div>
    </Container>
  )
}

export default NewsletterWrapper
