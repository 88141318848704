//const website = require('./website')

module.exports = {
  fr: {
    default: true,
    label: "Fr",
    path: "fr",
    locale: "fr",
    siteLanguage: "fr",
    ogLang: "fr_FR",

    readMore: "En savoir plus...",
    sending: "...",
    success: "Merci !",
    error: "Une erreur interne inattendue s'est produite.",
    empty: "Vous devez écrire un e-mail.",
    duplicate: "Trop de tentatives d'abonnement pour cette adresse email",
    emailPlaceHolder: "E-mail",
    yourMessage: "Message",
    send: "S’inscrire",
  },
  en: {
    default: false,
    label: "En",
    path: "en",
    locale: "en",
    siteLanguage: "en",
    ogLang: "en_GB",

    readMore: "Read more...",
  },
}
