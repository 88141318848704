import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import styled from "styled-components"
import PlayIcon from "../images/play.inline.svg"
import PauseIcon from "../images/pause.inline.svg"
import useStream from "../contexts/StreamWrapper"
import { subscribe, unsubscribe } from "pubsub-js"
import AudioPlayer from "./ui/AudioPlayerAlt"

const Button = styled.button`
  &:hover {
    svg path {
      fill: var(--color-accent);
    }
  }
`
const ProgramTitle = styled.div`
  &::before,
  &::after {
    content: "";
    width: var(--space-sm);
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    background-color: red;
  }
  &::before {
    left: 0;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
  }
  &::after {
    right: 0;
    background: linear-gradient(
      -90deg,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
  }
`

const Radio = () => {
  const [playing, setPlaying] = useState(false)
  // const refRadio = useRef()
  // const refMarquee = useRef()
  const { stream } = useStream()
  console.log(stream)
  useEffect(() => {
    const tokenPlayPause = subscribe("PLAYPAUSE", (e, d) => {
      setPlaying(d)
    })

    const tokenEnd = subscribe("AUDIO_END", () => {
      setPlaying(false)
      setTimeout(() => {
        setPlaying(true)
      }, 250)
    })

    return () => {
      unsubscribe(tokenPlayPause)
      unsubscribe(tokenEnd)
    }
  }, [])

  return !stream ? null : (
    <div
      className="radio flex flex-1 pr-md items-center cursor-pointer"
      onClick={() => setPlaying(!playing)}
      onKeyUp={() => setPlaying(!playing)}
      role="button"
      tabIndex={-1}
    >
      <AudioPlayer src={stream.src} playing={playing} />
      <Button className="play-pause ">
        {playing ? <PauseIcon /> : <PlayIcon />}
      </Button>
      <ProgramTitle className="program-title lh-1 px-sm- flex-1">
        {stream.title && (
          <marquee className="program-title align-top">
            {stream.title.fr}
          </marquee>
        )}
      </ProgramTitle>
    </div>
  )
}

export default Radio
