import { subscribe, unsubscribe } from "pubsub-js"
import React, { createContext, useContext } from "react"
import { useEffect } from "react"
import { useState } from "react"
import { modulo } from "../core/utils"
import { useSiteMetadata } from "../hooks/useSiteMetadata"

const StreamContext = createContext()
// const streamMixIndex = 0
export const StreamWrapper = ({ children }) => {
  const { settings } = useSiteMetadata()
  const { streamMix } = settings
  // console.log(settings)

  const [stream, dispatch] = useState()
  const [streamMixIndex, setStreamMixIndex] = useState(0)

  // let streamShow = null
  // if (settings.streamMix.length) streamShow = settings.streamMix[streamMixIndex]

  useEffect(() => {
    const token = subscribe("AUDIO_END", () => {
      _next()
    })

    // document.addEventListener("click", () => {
    //   const n = modulo(streamMixIndex, streamMix.length)
    //   console.log(n, streamMixIndex, streamMix.length)
    //   setStreamMixIndex(n)
    // })

    return () => unsubscribe(token)
  }, [streamMixIndex])

  const _next = () => {
    const n = modulo(streamMixIndex, streamMix.length)
    // console.log(n, streamMixIndex, streamMix.length)
    setStreamMixIndex(n)
    // const newIndex = streamMixIndex + 1
    // if (streamMix[newIndex]) {
    //   setStreamMixIndex(newIndex)
    // } else {
    //   setStreamMixIndex(0)
    // }
  }

  useEffect(() => {
    if (streamMix.length && streamMix[streamMixIndex])
      dispatch(streamMix[streamMixIndex])
  }, [streamMix, streamMixIndex])

  // console.log(streamMixIndex, stream)

  return (
    <StreamContext.Provider value={{ stream, dispatch }}>
      {children}
    </StreamContext.Provider>
  )
}

// export default StreamWrapper;
// export { StreamContext, StreamWrapper }

export default function useStream() {
  return useContext(StreamContext)
}
