import React, { useContext, useEffect, createContext, useState } from "react"
import { subscribe, unsubscribe } from "pubsub-js"
import Header from "./Header"
import Footer from "./Footer"
import Spirale from "./Spirale"
import NewsletterWrapper from "./ui/NewsletterWrapper"
import { StreamWrapper } from "../contexts/StreamWrapper"
// import { motion, AnimatePresence } from "framer-motion"
// import { scrollbarVisible } from "../core/utils"
// import Splash from "./Splash"

const WrapperContext = createContext({})

const duration = 0.15
const variants = {
  initial: {
    opacity: 0,
    y: 4,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    y: -4,
    transition: {
      duration: duration,
    },
  },
}

const Layout = ({ children, pageContext, location }) => {
  const settings = pageContext
  const { isHome } = settings
  const [visited, setVisited] = useState(false)

  const _format = () => {
    setTimeout(_detectScrollbarWidth, 50)

    document.documentElement.style.setProperty(
      "--app-height",
      window.innerHeight + "px"
    )

    document.documentElement.style.setProperty(
      "--app-width",
      window.innerWidth + "px"
    )

    const headerBounding = document
      .querySelector("header")
      .getBoundingClientRect()

    document.documentElement.style.setProperty(
      "--header-height",
      headerBounding.height + "px"
    )

    const footerBounding = document
      .querySelector("footer")
      .getBoundingClientRect()

    document.documentElement.style.setProperty(
      "--footer-height",
      footerBounding.height + "px"
    )

    const mainHeight = headerBounding.width - headerBounding.height
    document.documentElement.style.setProperty(
      "--main-aspect-ratio",
      `${window.innerWidth} / ${mainHeight}`
    )
  }

  useEffect(() => {
    _format()
    window.addEventListener("resize", _format)

    const token = subscribe("ROUTE_UPDATE", () => {
      setVisited(true)
    })

    /**
     * Modulo
     */
    // let n = 0
    // const length = 5
    // document.body.addEventListener("click", () => {
    //   let dividend = n + 1
    //   n = dividend % length

    //   console.log(n)
    // })

    return () => {
      window.removeEventListener("resize", _format)
      unsubscribe(token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const _detectScrollbarWidth = () => {
    // const srollbarVisbile = scrollbarVisible(document.body)

    const headerWith = document
      .querySelector("header")
      .getBoundingClientRect().width
    const windowWidth = document
      .querySelector("main")
      .getBoundingClientRect().width

    // console.log(headerWith, windowWidth)
    if (windowWidth < headerWith) {
      document.documentElement.classList.add("has-sb-visible")
    } else {
      document.documentElement.classList.remove("has-sb-visible")
    }

    // if (srollbarVisbile) {
    //   document.documentElement.classList.add("has-sb-visible")
    // } else {
    //   document.documentElement.classList.remove("has-sb-visible")
    // }
  }

  return (
    <WrapperContext.Provider value={{ settings, location }}>
      <div id="page">
        <div className="w-screen"></div>
        <StreamWrapper>
          <Header />

          {/* <AnimatePresence>
            <motion.div
              key={location.pathname}
              variants={variants}
              initial="initial"
              animate="enter"
              exit="exit"
              // ref={}
            >
              <main>{children}</main>
            </motion.div>
          </AnimatePresence> */}
          <main>{children}</main>

          <Footer />
          {isHome && <NewsletterWrapper />}
        </StreamWrapper>
      </div>
      <Spirale />
      {/* {isHome && !visited && <Splash delay={1000} />} */}
    </WrapperContext.Provider>
  )
}

export { WrapperContext, Layout }

export default function useWrapperContext() {
  return useContext(WrapperContext)
}
