import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { useScroll } from "../hooks/useScroll"
import LogoTexte from "../images/uyi-logo-texte.inline.svg"
import LogoSpirale from "../images/uyi-logo-spirale.inline.svg"
import { Link } from "gatsby"
import { subscribe, unsubscribe } from "pubsub-js"
import styled from "styled-components"

const LogoTexteWrapper = styled.div`
  @media all and (max-width: 768px) {
    transform: rotate(-72deg) translate(-50%, -50%) !important;
    min-width: 135vw;
    transform-origin: left top;
  }
`

const Spirale = () => {
  const [active, setActive] = useState(false)
  const [deg, setDeg] = useState(0)
  const { scrollY } = useScroll()

  useEffect(() => {
    // setDeg(scrollY / -45)
    setDeg(scrollY)
  }, [scrollY])

  useEffect(() => {
    const token = subscribe("ROUTE_UPDATE", () => {
      setActive(false)
    })
    return () => unsubscribe(token)
  }, [])

  return (
    <div
      className={clsx(
        "fixed center-x-y z-40 spirale",
        active ? "mix-blend-difference" : ""
      )}
    >
      <div className="inner">
        <div
          className="logo-spirale cursor-pointer  "
          onMouseEnter={() => setActive(true)}
          onMouseLeave={() => setActive(false)}
          role="button"
          tabIndex={0}
          style={{
            transform: `rotate(${deg}deg)`,
          }}
        >
          <Link to="/" title="Accueil">
            <div className="scale-75 md:scale-100">
              <LogoSpirale />
            </div>
          </Link>
        </div>
        {active && (
          <LogoTexteWrapper className="logo-texte-wrapper absolute center-x-y w-screen p-sm pointer-events-none   ">
            <LogoTexte />
          </LogoTexteWrapper>
        )}
      </div>
    </div>
  )
}

export default Spirale
