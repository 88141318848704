import locales from "../../config/i18n"
import useLocale from "../contexts/LocaleWrapper"

export function fileNameByUrl(url) {
  const decoded = decodeURIComponent(url)
  return decoded.substring(decoded.lastIndexOf("/") + 1)
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

export function _linkResolver(doc) {
  if (!doc || !doc.slug) return "/"
  // console.log(doc.type)
  switch (doc._type) {
    case "event":
      return `/event/${doc.slug.current}`

    case "tag":
      return `/tag/${doc.slug.current}`

    default:
      return `/${doc.slug.current}`
  }
}

export const _localizeText = text => {
  // const locale = "fr"
  const { locale } = useLocale()

  return locales[locale] && locales[locale][text] ? locales[locale][text] : text
}

export const _localizeField = field => {
  // console.log(field);
  const { locale } = useLocale()
  // const { locale } = "fr"
  // console.log(locale)
  if (!field) return ""
  return field && field[locale] ? field[locale] : field["fr"]
}

export const _excerptify = text => {
  if (!text) return ""
  let output = ""
  // if (text.length > 100) output = text.substring(0, 100) + "..."
  if (text.length > 100) output = text.split(".")[0] + "."

  return output
}

export const animateNumber = (start, end, duration) => {
  let startTimestamp = null
  const step = timestamp => {
    if (!startTimestamp) startTimestamp = timestamp
    const progress = Math.min((timestamp - startTimestamp) / duration, 1)

    if (progress < 1) {
      window.requestAnimationFrame(step)
    }
  }
  window.requestAnimationFrame(step)
}

// inspired by https://github.com/GoogleChrome/workbox/blob/3d02230f0e977eb1dc86c48f16ea4bcefdae12af/packages/workbox-core/src/_private/logger.ts

const styles = [
  `background: rebeccapurple`,
  `border-radius: 0.5em`,
  `color: white`,
  `font-weight: bold`,
  `padding: 2px 0.5em`,
].join(`;`)

export function debugLog(...args) {
  console.debug(`%cgatsby`, styles, ...args)
}

export const _getColorByEventType = evt => {
  switch (evt) {
    case "exposition":
      return "text-rouge"

    case "mediatheque":
      return "text-rose"

    case "radio":
      return "text-bleue"

    case "workshop":
      return "text-vert"

    case "projection":
      return "text-marron"

    case "concert":
      return "text-jaune"
    default:
      return "text-primary"
  }
}

export const _getBackgroundColorByEventType = evt => {
  switch (evt) {
    case "exposition":
      return "rouge"

    case "mediatheque":
      return "rose"

    case "radio":
      return "bleue"

    case "workshop":
      return "vert"

    case "projection":
      return "marron"

    case "concert":
      return "jaune"
    default:
      return "primary"
  }
}

export const _pad2 = n => {
  return (n < 10 ? "0" : "") + n
}

export const getCookie = cname => {
  let name = cname + "="
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(";")
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

export const get_youtube_video_id_by_url = url => {
  //eslint-disable-next-line no-useless-escape
  const reg =
    /^(https?:)?(\/\/)?((www\.|m\.)?youtube(-nocookie)?\.com\/((watch)?\?(feature=\w*&)?vi?=|embed\/|vi?\/|e\/)|youtu.be\/)([\w\-]{10,20})/i
  const match = url.match(reg)
  if (match) {
    return match[9]
  } else {
    return null
  }
}

export const get_youtube_video_thumbnail_by_url = url => {
  // console.log(url);
  const ID = get_youtube_video_id_by_url(url)
  return `https://i.ytimg.com/vi/${ID}/hqdefault.jpg`
}

export const modulo = (n, length) => {
  n = (n + 1) % length
  return n
}

export const scrollbarVisible = element => {
  return element.scrollHeight > element.clientHeight
}
