import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { _linkResolver, _localizeField } from "../core/utils"
import Tags from "./Tags"
import clsx from "clsx"
import { useEffect } from "react"
import { subscribe, unsubscribe } from "pubsub-js"
// import useWrapperContext from "./Layout"
import SanityTexte from "./SanityTexte"
import Radio from "./Radio"
import useDeviceDetect from "../hooks/useDeviceDetect"

const query = graphql`
  query {
    sanityHeader {
      siteName
      tags {
        ... on SanityInternalLink {
          _type
          label {
            ...localeString
          }
          link {
            ... on SanityTag {
              _type
              slug {
                current
              }
              color
            }
          }
        }
      }
      # stream
      nav {
        ... on SanityInternalLink {
          _type
          label {
            ...localeString
          }
          link {
            ... on SanityPage {
              _type
              slug {
                current
              }
            }
            ... on SanityAgenda {
              _type
              slug {
                current
              }
            }
          }
        }
      }
      mobileInfos: _rawMobileInfos(resolveReferences: { maxDepth: 10 })
    }
  }
`

const Header = () => {
  const {
    sanityHeader: { siteName, nav, tags, mobileInfos },
  } = useStaticQuery(query)
  // const { settings } = useWrapperContext()
  const { isMobile } = useDeviceDetect()
  // console.log(settings)
  const [active, setActive] = useState(false)

  useEffect(() => {
    const token = subscribe("ROUTE_UPDATE", () => {
      setActive(false)
    })
    return () => unsubscribe(token)
  }, [])

  return (
    <header className=" sticky top-0 z-50 w-full text-_sm md:text-lg bg-primary">
      <div className="header-desktop hidden-sm">
        <div className="flex flex-wrap md:flex-nowrap md:justify-between items-center">
          {!isMobile && (
            <div className="radio w-full md:w-1/3">
              <Radio />
            </div>
          )}
          <div className="tags w-full md:w-1/3">
            <div className="site-name text-md text-center">{siteName}</div>
            <Tags input={tags} />
          </div>
          <div className="nav md:w-1/3">
            <nav id="nav-primary">
              <ul className="flex justify-end">
                {nav.map((li, i) => (
                  <li key={i} className="pl-md nav-link--page">
                    <Link to={_linkResolver(li.link)}>
                      {_localizeField(li.label)}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <div className="header-mobile sm-only text-md">
        <div className="flex justify-between">
          <div>{isMobile && <Radio />}</div>
          <button className="sm-only lh-1" onClick={() => setActive(true)}>
            Menu
          </button>
        </div>
        <div className="flex justify-center items-baseline mb-sm">
          {/* <div>
            <Link to="/" className="text-accent">
              Close
            </Link>
          </div> */}
          {/* {settings.h1 && (
            <h1 className="text-_md text-center">{settings.h1.fr}</h1>
          )} */}

          <nav
            id="nav-primary"
            className={clsx(
              "fixed inset-0 bg-black text-primary p-sm z-50 h-app overflow-x-auto",
              active ? "block" : "hidden"
            )}
          >
            <button
              className="absolute right-sm top-sm "
              onClick={() => setActive(false)}
            >
              Close
            </button>
            <ul className="text-_xl py-1e">
              {nav.map((li, i) => (
                <li key={i} className="nav-link--page text-center">
                  <Link to={_linkResolver(li.link)}>
                    {_localizeField(li.label)}
                  </Link>
                </li>
              ))}
            </ul>
            <SanityTexte input={_localizeField(mobileInfos)} />
          </nav>
        </div>

        <Tags input={tags} />
      </div>
    </header>
  )
}

export default Header
