import React from "react"
import clsx from "clsx"
import styled from "styled-components"
// import { _getBackgroundColorByEventType } from "../core/utils"
import { Link } from "gatsby"
import { _linkResolver, _localizeField } from "../core/utils"
import useWrapperContext from "./Layout"
import { motion } from "framer-motion"
import { useState } from "react"

const Scene = styled.div`
  --cube-width: 50px;
  --cube-height: 21px;
  /* background-color: orange; */
  @media all and (max-width: 768px) {
    /* --cube-width: 37px; */
    --cube-width: 45px;
    --cube-height: 16px;
  }
  --color: ${props => `var(--color-${props.color})` || "black"};

  width: var(--cube-width);
  height: var(--cube-height);
  perspective: 500px;
  backface-visibility: hidden; //reduces flickering in -webkit
  margin-left: -3px;
  &:hover {
    .cube {
      transform: rotateY(-10deg) rotateX(-225deg);
    }
  }

  .cube {
    position: relative;
    width: var(--cube-width);
    height: var(--cube-height);
    transform: rotateY(-10deg) rotateX(-45deg);
    transform-style: preserve-3d;
    transition: transform 0.5s ease;
    z-index: -1;
  }
  .face {
    position: absolute;
    width: var(--cube-width);
    height: var(--cube-height);
    backface-visibility: visible;
    background-color: var(--color);
    opacity: 0.9;
    transform-origin: center center;

    filter: brightness(85%);
    &.face--front {
      filter: none;
    }
    &:nth-child(1) {
      transform: translate3d(0, 0, calc(var(--cube-height) / 2));
      opacity: 1;
    }
    &:nth-child(2) {
      transform: rotateY(90deg)
        translate3d(0, 0, calc(var(--cube-width) - var(--cube-height) / 2));
      width: var(--cube-height);
      height: var(--cube-height);
    }
    &:nth-child(3) {
      transform: rotateY(180deg) translate3d(0, 0, calc(var(--cube-height) / 2));
    }
    &:nth-child(4) {
      transform: rotateY(270deg) translate3d(0, 0, calc(var(--cube-height) / 2));
      width: var(--cube-height);
      height: var(--cube-height);
    }
    &:nth-child(5) {
      transform: rotateX(90deg) translate3d(0, 0, calc(var(--cube-height) / 2));
    }
    &:nth-child(6) {
      transform: rotateX(270deg) translate3d(0, 0, calc(var(--cube-height) / 2));
    }
  }
`

const Box = ({ color }) => (
  <Scene className="scene" color={color}>
    <div className="cube">
      <div className="face face--front"></div>
      <div className="face"></div>
      <div className="face"></div>
      <div className="face"></div>
      <div className="face "></div>
      <div className="face "></div>
    </div>
  </Scene>
)

const Tags = ({ input }) => {
  const { settings } = useWrapperContext()
  const { showTags } = settings
  const [tooltip, setTooltip] = useState(null)

  const container = {
    // hidden: { opacity: 0 },
    show: {
      transition: {
        staggerChildren: 0.05,
        // delayChildren: 0.05,
        staggerDirection: 1,
      },
    },
    hidden: {
      transition: {
        staggerChildren: 0.05,
        // delayChildren: 0.5,
        staggerDirection: -1,
      },
    },
  }

  const item = {
    hidden: { opacity: 0, y: -100, transition: { duration: 0.5 } },
    show: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  }

  return (
    <motion.ul
      variants={container}
      initial="hidden"
      animate={showTags ? "show" : "hidden"}
      className={clsx(" flex justify-center md:absolute md:center-x-y z-10-")}
    >
      {input.map((tag, i) => (
        <motion.li
          key={i}
          variants={item}
          className={clsx("evt cursor-pointer")}
        >
          <Link
            to={_linkResolver(tag.link)}
            onMouseEnter={() => setTooltip(tag)}
            onMouseLeave={() => setTooltip(null)}
            title={_localizeField(tag.label)}
          >
            <Box color={tag.link.color} className={tag.link.slug?.current} />
          </Link>
        </motion.li>
      ))}

      {tooltip && (
        <div
          className={clsx(
            "hidden-sm current-tag text-xl absolute left-1/2 -translate-x-1/2 translate-y-full whitespace-nowrap capitalize",
            `text-${tooltip.link.color}`
          )}
        >
          {tooltip.label.fr}
        </div>
      )}
    </motion.ul>
  )
}

export default Tags
